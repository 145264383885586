import React from 'react';
import './List.css';

function ListTextItem({children, isHeader = false, type = 0, size = 'big'}) {
  return (
    <div className={"listTextContainer"}>
      <div className={`${isHeader ? `header` : `normal`} ${type === 0 ? "text" : type === 1 ? "accent" : "positive"} ${size}`}>
        {children}
      </div>
    </div>
  )
}

export default ListTextItem