import React, { useEffect, useState } from 'react';
import './SendMailScreen.css';
import { useSuccessBar } from '../context/SuccessBarContext';
import { usePopup } from '../context/PopupContext';
import SubmitButton from '../input/SubmitButton';
import Textfield from '../input/Textfield';
import CustomDropdown from '../input/CustomDropdown';
import api from '../wrapper/API';
import Popup from '../popup/Popup';

function SendMailScreen({postEvent, setPostEvent, postPlayer, setPostPlayer}) {

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const {openPopup} = usePopup();
  const [selectedTextBlock, setSelectedTextBlock] = useState("");
  const [selectedTextBlockName, setSelectedTextBlockName] = useState("");
  const [error, setError] = useState("");
  const [textblocks, setTextblocks] = useState([]);

  useEffect(() => {
    api
      .get(`/Textblock/GetAllNames?language=DE`)
      .then((response) => {
        setTextblocks(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  const sendMail = (event) => {
    startSuccessBarLoading("Email wird gesendet")
    event.preventDefault();
    
    api.post(`/Textblock/SendAsEmail?name=${selectedTextBlockName}&language=DE&eventId=${postEvent.id}&playerId=${postPlayer.id}`)
      .then((response) => {
        startSuccessBarSuccess("Email wurde gesendet");
        setSelectedTextBlock("");
        setSelectedTextBlockName("");
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("mailrerror", 1);
      })
  };

  const handleDropdownChange = (event) => {
    if (!event.target.value) return;
    api
      .get(`/Textblock/GetByName?name=${event.target.value}&language=DE&eventId=${postEvent.id}&playerId=${postPlayer.id}`)
      .then((response) => {
        setSelectedTextBlock(response.data.textFinalHtml);
        setSelectedTextBlockName(response.data.name);
      })
      .catch((error) => {
        setError(error.response.data);
        openPopup("mailrerror", postPlayer.id);
      });
  };

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        <div className={"innerContainer"}>
          <div className={"generalInformationContainer"}>
            <label className={"innerContainerLabel"}>Grundlagen</label>
            <Textfield required={true} icon={"event"} type={"date"} name={"date"} placeholder={"Datum"} readOnly value={postEvent ? postEvent.date : ''} />
            <Textfield required={false} icon={"id_card"} type={"text"} name={"nachname"} placeholder={"Nachname"} value={postPlayer ? postPlayer.lastname : ''} />
            <Textfield required={true} icon={"person"} type={"text"} name={"vorname"} placeholder={"Nachname"} value={postPlayer ? postPlayer.firstname : ''} />
            <Textfield required={true} icon={"send"} type={"text"} name={"email"} placeholder={"E-Mail"} value={postPlayer ? postPlayer.email : ''} />
            <CustomDropdown value={""} onChange={handleDropdownChange} icon={"message"}>
              <option value="" disabled hidden></option>
              {textblocks.map((textblock, index) => (
                <option key={index} value={textblock.name}>{textblock.frontendname}</option>
              ))}
            </CustomDropdown>
            <div className={"buttonsIconMailContainer"}>
              <SubmitButton icon={"send"} onClick={sendMail} styletype={"smallround"} tooltip={"Senden"} type={2} />
              <SubmitButton icon={"email"} onClick={() => {}} styletype={"smallround"} tooltip={"Im Emailprogramm öffnen"} />
            </div>
          </div>
          <div className={"sideContainer"}>
            <label className={"innerContainerLabel"}>Vorschau</label>
            <div className={"mailVorschau"} dangerouslySetInnerHTML={{ __html: selectedTextBlock }}>
            </div>
          </div>
        </div>
      </main>
      <Popup name='mailrerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default SendMailScreen