import React from 'react';
import './ListSymbols.css';

function ListSymbols({children}) {
  return (
    <div className={"listSymbolsContainer"}>
      {children}
    </div>
  )
}

export default ListSymbols