import React, { useState, useContext, useCallback } from 'react';

const SuccessBarContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const useSuccessBar = () => {
  return useContext(SuccessBarContext);
};

// Kontextanbieter-Komponente
export const SuccessBarProvider = ({ children }) => {
  const [successBarState, setSuccessBarState] = useState("closed");
  const [successBarMessage, setSuccessBarMessage] = useState('');

  const closeSuccessBar = useCallback(() => {
    setSuccessBarState("closed");
  }, []);

  const startSuccessBarLoading = useCallback((message) => {
    setSuccessBarState("loading");
    setSuccessBarMessage(message);
  }, []);

  const startSuccessBarSuccess = useCallback((message) => {
    setSuccessBarState("success");
    setSuccessBarMessage(message);
  }, []);

  return (
    <SuccessBarContext.Provider value={{ successBarState, successBarMessage, startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar }}>
      <div>
        {children}
      </div>
    </SuccessBarContext.Provider>
  );
};