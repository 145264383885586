import React, { forwardRef } from 'react';
import './Icon.css';

function Icon({ icon, className, onClick}, ref) {
  return (
    <div className={"customIconContainer"}>
      <span ref={ref} className={`material-symbols-rounded ${className}`} onClick={onClick}>{icon}</span>
    </div>
  );
}

export default forwardRef(Icon);