import React, { forwardRef } from 'react';
import './Togglebox.css';
import Icon from '../home/Icon';

function Togglebox({onChange, checked = false}, ref) {
  return (
    <label className={"switch"}>
      <input ref={ref} checked={checked} onChange={onChange} type="checkbox" />
        <div className={"slider"}>
          <div className={"circle"}>
            <Icon className={"cross"} icon='close' />
            <Icon className={"checkmark"} icon='check_small' />
          </div>
        </div>
    </label>
  )
}

export default forwardRef(Togglebox)