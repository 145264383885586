import React, { useRef, useState } from 'react';
import './Login.css';
import api from '../wrapper/API';
import { motion } from 'framer-motion';
import logo from '../../images/favicon.ico';
import Textfield from '../input/Textfield';
import { useSuccessBar } from '../context/SuccessBarContext';
import { useLocation, useNavigate } from 'react-router-dom';

function ResetPasswordForm() {
  const emailTextFieldRef = useRef();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const {startSuccessBarSuccess} = useSuccessBar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const navigate = useNavigate();

  const handleSubmit = (event) => { 
    event.preventDefault();   
    if (password !== repeatPassword) {
      setError("Die zwei Passwortfelder stimmen nicht überein.");
      return;
    }

    setLoading(true);
    const requestBody = {
      id: id,
      newPassword: password
    }
    
    api.put(`/Login/ResetPassword?id=${requestBody.id}&newPassword=${requestBody.newPassword}`)
      .then((response) => {
        setLoading(false);
        setError(null);
        startSuccessBarSuccess("Dein Passwort wurde geändert");
        navigate('/login');
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data);
      })
  };

  return (
    <React.Fragment>
      <div className={"loginContainer"}>
        <div className={"loginFormContainer"}>
          <img className={"logo"} src={logo} />
          <p className={"loginLabel"}>Passwort ändern</p>
          <form onSubmit={handleSubmit}>
            <div className={"textFieldGeneralContainer"}>
              <Textfield icon={"vpn_key"} ref={emailTextFieldRef} type={"password"} required={true} name={"password"} placeholder={"Neues Passwort"} onChange={(e) => setPassword(e.target.value)} value={password}  />
              <Textfield icon={"vpn_key"} ref={emailTextFieldRef} type={"password"} required={true} name={"repeatPassword"} placeholder={"Passwort wiederholen"} onChange={(e) => setRepeatPassword(e.target.value)} value={repeatPassword}  />
            </div>
            <div className={"forgotPassword"} onClick={() => navigate("/login")}>Zurück zum Login</div>
            {error !== null && <p className={"loginError"}>{error}</p>}
            <div className={"submitContainer"}>
              {loading ?  <div className="submitLoadingContainer">
                {[1, 2, 3].map((i) => {
                  return (
                    <motion.div
                      key={i} 
                      className="submitLoadingIcon"
                      animate={{
                        scale: [1, 1.8, 1]
                      }}
                      transition={{
                        delay: i * 0.2,
                        duration: 0.6,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 0.5
                      }}
                    ></motion.div>
                  )
                })}
              </div> : <input className="submitButton" type='submit' value='Passwort ändern' />}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordForm