import React, { useEffect, useState } from 'react';
import Navbar from '../nav/Navbar';
import '../mail/SendMailScreen.css';
import '../styles/MainContainer.css';
import SendMailScreen from '../mail/SendMailScreen';
import { useParams } from 'react-router-dom';
import api from '../wrapper/API';

function Mail() {
  const { eventId } = useParams();
  const { playerId } = useParams();

  const [postEvent, setPostEvent] = useState({});
  const [postPlayer, setPostPlayer] = useState({});

  useEffect(() => {
    api.get(`/Event/GetById?eventId=${eventId}`)
      .then(response => {
        setPostEvent(response.data);
      })
      .catch(() => {

      });

    api.get(`/Player/GetPlayerById?playerId=${playerId}`)
      .then(response => {
        setPostPlayer(response.data);
      })
      .catch(() => {

      });
  }, [eventId]);

  return (
    <React.Fragment>
      <Navbar extended={true} />
      <div className={"generalBigContainer"}>
        <SendMailScreen postEvent={postEvent} setPostEvent={setPostEvent} postPlayer={postPlayer} setPostPlayer={setPostPlayer} />
      </div>
    </React.Fragment>
  );
}

export default Mail;