import React, { forwardRef } from 'react';
import './Textfield.css';
import Icon from '../home/Icon';

function Textfield({ placeholder = "", type = "", required = false, name = "", icon = "", fill = false, onChange = () => {}, value = "" }, ref) {
  return (
    <div className={`textFieldContainer ${icon ? 'withIcon' : 'withoutIcon'}`}>
      {icon && (
        <div className={"textFieldIconContainer"}>
          <Icon className={`textFieldIcon ${fill ? "filled" : "notfilled"}`} icon={icon} />
        </div>
      )}
      <input ref={ref} type={type} required={required} name={name} placeholder={placeholder} onChange={onChange} value={value} />
    </div>
  );
}

export default forwardRef(Textfield)