import React, { useState } from 'react';
import './List.css';
import Icon from '../home/Icon';
import { useContextMenu } from '../context/ContextMenuContext';
import { motion } from 'framer-motion';

function WikiListItem({id = 0, title = 'Title', description = 'desc', priority = 1, orderNumber = 1, index, withOrderNumber = false, withPriority = false}) {
  //Context Menu
  const { openContextMenu } = useContextMenu();

  const handleContextMenu = (event) => {
    openContextMenu(event, id, 'rules');
  };

  const listItemVariants = {
    initial: {
      opacity: 0,
      scale: 1
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.06 * index,
        duration: 0.4
      }
    },
    hover: {
      scale: 1.02,
      cursor: "pointer"
    },
    click: {
      scale: 0.98,
      transition: {
        duration: 0.001
      }
    }
  }

  const [longPressTimer, setLongPressTimer] = useState(null);

  const handleTouchMove = () => {
    if (longPressTimer !== null) {
      clearTimeout(longPressTimer);
    }
  };

  const handleTouchStart = (event) => {
    setLongPressTimer(setTimeout(() => {
      handleContextMenu(event);
    }, 150)); // Stellen Sie die Dauer des langen Berührens in Millisekunden ein
  }

  const handleTouchEnd = (event) => {
    if (longPressTimer !== null) {
      clearTimeout(longPressTimer);
    }
  }

  return (
    <motion.div 
      className={`wikiListItemContainer ${withOrderNumber ? "withOrderNumber" : ""} ${withPriority ? "withPriority" : ""}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onContextMenu={handleContextMenu}
      variants={listItemVariants}
      initial="initial"
      animate="visible"
      whileHover="hover"
      whileTap="click"
    >
      {withOrderNumber && <p className={"orderText"}>{orderNumber}</p>}
      <div className={"textContainer"}>
        <p className={"title"}>{title}</p>
        <p className={"desc"}>{description}</p>
      </div>
      {priority === 2 && withPriority && <Icon className={"priorityIcon"} icon='priority_high' />}
    </motion.div>
  )
}

export default WikiListItem