import React from 'react';
import { DarkModeProvider } from './components/context/DarkModeContext';
import { ErrorPopupProvider } from './components/context/ErrorPopupContext';
import Wrapper from './components/wrapper/Wrapper';
import { InfoPopupProvider } from './components/context/InfoPopupContext';
import { AuthProvider } from './components/context/AuthContext';
import { SuccessBarProvider } from './components/context/SuccessBarContext';
import { PopupProvider } from './components/context/PopupContext';

function App() {
  return (
    <DarkModeProvider><PopupProvider><ErrorPopupProvider><InfoPopupProvider><AuthProvider><SuccessBarProvider>
      <Wrapper />
    </SuccessBarProvider></AuthProvider></InfoPopupProvider></ErrorPopupProvider></PopupProvider></DarkModeProvider>
  );
}

export default App;