import React, { useState, useContext, useCallback } from 'react';

// Erstelle den Kontext
const ErrorPopupContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const useErrorPopup = () => {
  return useContext(ErrorPopupContext);
};

// Kontextanbieter-Komponente
export const ErrorPopupProvider = ({ children }) => {
  const [isErrorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({ message: 'NaN', redirect: false });

  // Funktion zum Öffnen des Kontextmenüs
  const openErrorPopup = useCallback((message, redirect) => {
    setErrorPopupData({ message, redirect });
    setErrorPopupOpen(true);
  }, []);

  // Funktion zum Schließen des Kontextmenüs
  const closeErrorPopup = useCallback(() => {
    setErrorPopupOpen(false);
  }, []);

  return (
    <ErrorPopupContext.Provider value={{ isErrorPopupOpen, errorPopupData, openErrorPopup, closeErrorPopup }}>
      {children}
    </ErrorPopupContext.Provider>
  );
};