// Packages
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const {isAuth} = useAuth();

  const [authenticated, setAuthenticated] = useState(isAuth);

  useEffect(() => {
    setAuthenticated(isAuth);
  }, [isAuth])
  
  return (
    authenticated ? children : <Navigate to="/login" />
  );
}

export default ProtectedRoute