import React, { useState, useContext, useCallback, useEffect } from 'react';

// Erstelle den Kontext
const ContextMenuContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const useContextMenu = () => {
  return useContext(ContextMenuContext);
};

// Kontextanbieter-Komponente
export const ContextMenuProvider = ({ children }) => {
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuData, setContextMenuData] = useState({identifier: null, type: '' });
  const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0})
  const [contextMenuOffset, setOffset] = useState({ width: 0, height: 0 });

  const setPosition = (x, y) => {
    const menuWidth = contextMenuOffset.width;
    const menuHeight = contextMenuOffset.height;

    // Hier fügst du die Überprüfung auf den Bildschirmbereich hinzu
    const screenWidth = window.innerWidth - 20;
    const screenHeight = window.innerHeight - 20;

    // Überprüfe, ob das Menü über den rechten Bildschirmrand hinausgeht
    if (x + menuWidth > screenWidth) {
      x = screenWidth - menuWidth;
    }

    // Überprüfe, ob das Menü unter den unteren Bildschirmrand hinausgeht
    if (y + menuHeight > screenHeight + window.scrollY) {
      y = screenHeight - menuHeight + window.scrollY;
    }

    setContextMenuPosition({ x, y })
  }

  useEffect(() => {
    setPosition(contextMenuPosition.x, contextMenuPosition.y)
  }, [contextMenuOffset])

  // Funktion zum Öffnen des Kontextmenüs
  const openContextMenu = useCallback((event, identifier, type) => {
    event.preventDefault();
    if (event.touches) {
      setPosition(event.touches[0].pageX, event.touches[0].pageY);
    } else {
      setPosition(event.pageX, event.pageY);
    }
    setContextMenuData({ identifier, type });
    setContextMenuOpen(true);
  }, [contextMenuOffset]);

  // Funktion zum Schließen des Kontextmenüs
  const closeContextMenu = useCallback(() => {
    setContextMenuOpen(false);
  }, []);

  const setContextMenuOffset = useCallback((width, height) => {
    setOffset({width, height});
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isContextMenuOpen && event.target.innerHTML !== 'settings') {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isContextMenuOpen, closeContextMenu]);

  return (
    <ContextMenuContext.Provider value={{ isContextMenuOpen, contextMenuData, contextMenuPosition, setContextMenuOffset, openContextMenu, closeContextMenu }}>
      <div>
        {children}
      </div>
    </ContextMenuContext.Provider>
  );
};