import React from 'react';
import './SubmitButton.css';
import { motion } from 'framer-motion';
import Icon from '../home/Icon';
import Tooltip from '../tooltip/Tooltip';

function SubmitButton({icon = 'person_add', onClick = () => {}, styletype = 'round', type = 3, style={}, tooltip = ''}) {
  const buttonVariants = {
    initial: {
      scale: 1
    },
    hover: {
      scale: 1.07,
      cursor: "pointer"
    },
    click: {
      scale: 0.90,
      transition: {
        duration: 0.01
      }
    }
  }

  return (
    <motion.button 
      type='submit' 
      className={`addNew ${styletype} ${
        type === 1
        ? "negative"
        : type === 2
        ? "positive"
        : type === 3
        ? "accent"
        : ''
      }`}
      variants={buttonVariants}
      initial="initial"
      whileHover="hover"
      whileTap="click"
      onClick={onClick}
      style={style}
    >
      <Tooltip text={tooltip}>
        <Icon className={`addNewIcon ${styletype}`} icon={icon} />
      </Tooltip>
    </motion.button>
  )
}

export default SubmitButton