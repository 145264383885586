import React from 'react';
import './CustomComboBox.css';

function CustomComboBox({ children, placeholder, selected = 0, onStatusChange, type = "createform"}) {

  const handleCellClick = (index) => {
    if (onStatusChange) {
      onStatusChange(index);
    }
  };

  const gridItems = React.Children.map(children, (child, index) => {
    const isSelected = index === selected;

    return (
      <div className={`customComboBoxItemContainer  ${isSelected ? "selected" : "deselected"}`} onClick={() => handleCellClick(index)}>
        {child}
      </div>
    );
  });

  return (
    <div className={`customComboBoxContainer ${type}`}>
      {placeholder ? <p>{placeholder}:</p> : ''}
      {gridItems}
    </div>
  );
}

export default CustomComboBox;