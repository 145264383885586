import React, { useEffect, useState } from 'react'
import Tooltip from '../tooltip/Tooltip';
import './EventStatus.css';

function EventStatus({statusId = 0, style = {}}) {
  let output = "";
  const [tooltiptext, setTooltiptext] = useState('');

  useEffect(() => {
    switch (statusId) {
      case 1:
        setTooltiptext('Erstellt');
        break;
      case 2:
        setTooltiptext('Geplant');
        break;
      case 3:
        setTooltiptext('Erfolgreich');
        break;
      case 4:
        setTooltiptext('Abgesagt');
    } 
  }, [tooltiptext])

  switch (statusId) {
    case 1:
      output = <svg id={statusId} className={"svgEventStatus"} style={style} width="750" height="250" viewBox="0 0 750 250" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className={"background"} fillRule="evenodd" clipRule="evenodd" d="M165 75C151.739 75 139.021 80.2678 129.645 89.6447C120.268 99.0215 115 111.739 115 125C115 138.261 120.268 150.979 129.645 160.355C139.021 169.732 151.739 175 165 175L165 125L165 175H584C597.261 175 609.979 169.732 619.355 160.355C628.732 150.979 634 138.261 634 125C634 111.739 628.732 99.0215 619.355 89.6447C609.979 80.2679 597.261 75 584 75L165 75Z"/>
              <circle cx="165" cy="125" r="92"/>
              </svg>
      break;
    case 2:
      output = <svg id={statusId} className={"svgEventStatus"} style={style} width="750" height="250" viewBox="0 0 750 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={"background filled"} fillRule="evenodd" clipRule="evenodd" d="M129.645 89.6447C139.021 80.2678 151.739 75 165 75L375 75V175L165 175C151.739 175 139.021 169.732 129.645 160.355C120.268 150.979 115 138.261 115 125C115 111.739 120.268 99.0215 129.645 89.6447Z"/>
                <path className={"background"} fillRule="evenodd" clipRule="evenodd" d="M584 75H375V175H584C597.261 175 609.979 169.732 619.355 160.355C628.732 150.979 634 138.261 634 125C634 111.739 628.732 99.0215 619.355 89.6447C609.979 80.2679 597.261 75 584 75Z"/>
                <circle cx="375" cy="125" r="92"/>
                </svg>
      break;
    case 3:
      output = <svg id={statusId} className={"svgEventStatus"} style={style} width="750" height="250" viewBox="0 0 750 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={"background filled"} fillRule="evenodd" clipRule="evenodd" d="M165 75C151.739 75 139.021 80.2678 129.645 89.6447C120.268 99.0215 115 111.739 115 125C115 138.261 120.268 150.979 129.645 160.355C139.021 169.732 151.739 175 165 175L584 175C597.261 175 609.979 169.732 619.355 160.355C628.732 150.979 634 138.261 634 125C634 111.739 628.732 99.0215 619.355 89.6447C609.979 80.2679 597.261 75 584 75L165 75Z"/>
                <circle cx="584" cy="125" r="92" fill="#6ACC3C"/>
                <path className={"symbol"} fillRule="evenodd" clipRule="evenodd" d="M647.377 85.1743C646.1 84.1009 644.625 83.2893 643.035 82.7861C641.445 82.2828 639.771 82.0977 638.109 82.2412C636.448 82.3848 634.83 82.8543 633.35 83.6228C631.87 84.3913 630.556 85.4439 629.482 86.7204L639.202 94.8947L629.482 86.7204L576.775 149.396L538.564 104.657C537.481 103.389 536.158 102.347 534.672 101.59C533.186 100.832 531.565 100.375 529.903 100.245C528.24 100.114 526.568 100.312 524.981 100.827C523.395 101.342 521.926 102.165 520.658 103.248C519.389 104.332 518.347 105.654 517.59 107.14C516.833 108.626 516.376 110.247 516.245 111.91C516.114 113.572 516.312 115.245 516.827 116.831C517.343 118.417 518.165 119.886 519.248 121.154L566.382 176.341L567.629 175.276L566.383 176.341C567.466 177.609 568.788 178.651 570.275 179.409C571.761 180.166 573.381 180.623 575.044 180.754C576.357 180.857 577.675 180.755 578.953 180.454C578.965 180.453 578.976 180.452 578.988 180.451C580.65 180.308 582.267 179.838 583.747 179.07C585.228 178.301 586.542 177.249 587.615 175.972L648.923 103.069L639.202 94.8947L648.923 103.069C649.996 101.793 650.808 100.317 651.311 98.727C651.814 97.1369 652 95.4632 651.856 93.8015C651.712 92.1398 651.243 90.5227 650.474 89.0424C649.706 87.5622 648.653 86.2478 647.377 85.1743Z"/>
                </svg>
      break;
    case 4:
      output = <svg id={statusId} className={"svgEventStatus"} style={style} width="750" height="250" viewBox="0 0 750 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={"background"} fillRule="evenodd" clipRule="evenodd" d="M165 75C151.739 75 139.021 80.2678 129.645 89.6447C120.268 99.0215 115 111.739 115 125C115 138.261 120.268 150.979 129.645 160.355C139.021 169.732 151.739 175 165 175L584 175C597.261 175 609.979 169.732 619.355 160.355C628.732 150.979 634 138.261 634 125C634 111.739 628.732 99.0215 619.355 89.6447C609.979 80.2679 597.261 75 584 75L165 75Z"/>
                <circle cx="584" cy="129" r="92"/>
                <path className={"symbol"} fillRule="evenodd" clipRule="evenodd" d="M636.551 96.6985C637.851 95.3985 638.882 93.8551 639.586 92.1566C640.29 90.458 640.652 88.6375 640.652 86.799C640.652 84.9605 640.29 83.14 639.586 81.4414C638.882 79.7429 637.851 78.1995 636.551 76.8995C635.251 75.5995 633.708 74.5682 632.009 73.8647C630.311 73.1611 628.49 72.799 626.652 72.799C624.813 72.799 622.993 73.1611 621.294 73.8647C619.596 74.5682 618.052 75.5995 616.752 76.8995L584.225 109.426L551.699 76.8995C550.398 75.5996 548.855 74.5684 547.157 73.8648C545.458 73.1613 543.638 72.7991 541.799 72.7991C539.961 72.7991 538.14 73.1613 536.441 73.8648C534.743 74.5684 533.2 75.5996 531.9 76.8996C530.6 78.1997 529.568 79.743 528.865 81.4416C528.161 83.1401 527.799 84.9606 527.799 86.7991C527.799 88.6376 528.161 90.4581 528.865 92.1567C529.568 93.8553 530.6 95.3986 531.9 96.6985L564.426 129.225L531.899 161.752C530.6 163.052 529.568 164.596 528.865 166.294C528.161 167.993 527.799 169.813 527.799 171.652C527.799 173.49 528.161 175.311 528.865 177.009C529.568 178.708 530.6 180.251 531.9 181.551C533.2 182.851 534.743 183.883 536.442 184.586C538.14 185.29 539.961 185.652 541.799 185.652C543.638 185.652 545.458 185.29 547.157 184.586C548.855 183.883 550.399 182.851 551.698 181.551L584.225 149.024L616.752 181.551C618.052 182.851 619.596 183.882 621.294 184.586C622.993 185.29 624.813 185.652 626.652 185.652C628.49 185.652 630.311 185.29 632.009 184.586C633.708 183.882 635.251 182.851 636.551 181.551C637.851 180.251 638.883 178.708 639.586 177.009C640.29 175.311 640.652 173.49 640.652 171.652C640.652 169.813 640.29 167.993 639.586 166.294C638.883 164.596 637.851 163.052 636.551 161.752L604.024 129.225L636.551 96.6985Z"/>
                </svg>
      break;
    default:
      break;
  }

  return (
    <Tooltip text={tooltiptext}>
      <div className={"svgEventStatusContainer"}>
        {output}
      </div>
    </Tooltip>
  )
}

export default EventStatus