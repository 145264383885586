import React, { useState, useRef } from 'react'
import '../styles/MainContainer.css';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import StatusDot from '../status/StatusDot';
import './EventList.css';
import '../popup/EditPopup.css';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import Checkbox from '../input/Checkbox';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import { useNavigate } from 'react-router-dom';
import ListTextItem from '../list/ListTextItem';
import ListSymbols from '../input/ListSymbols';
import ListSymbol from '../input/ListSymbol';
import PopupDescriptionItem from '../popup/PopupDescriptionItem';
import PopupDescriptionLinkItem from '../popup/PopupDescriptionLinkItem';
import ContextMenuSeparator from '../ContextMenu/ContextMenuSeparator';
import CustomComboBox from '../input/CustomComboBox';

function EventList({posts, setPosts, eventId, onListItemClick, eventDate, addPlayer = () => {}}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const {openPopup, popupData, closePopup} = usePopup();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [selectedSendUnregisterMailStatus, setSelectedSendUnregisterMailStatus] = useState(0);

  //Checkbox Anwesend
  const handleListItemClick = (event, playerId) => {
    if (localStorage.getItem("statusId") === "4") {
      openPopup("warningdeleteeventplayer", {playerId: playerId, event: event});
    } else if (new Date().setHours(0, 0, 0, 0) !== new Date(eventDate).setHours(0, 0, 0, 0)) {
      openPopup("warningchangeeventplayer", {playerId: playerId, event: event});
    } else {
      changePlayerPresence(event, playerId);
    }
  };

  const handleChangePlayerPresence = () => {
    changePlayerPresence(popupData.identifier.event, popupData.identifier.playerId);
  }

  function changePlayerPresence(event, playerId) {
    const foundPost = posts.find(post => post.id === playerId);
    const present = foundPost.present === 2 ? 1 : foundPost.present + 1;
    updatePlayerPresence(event, playerId, present);
  }

  function resetPlayerPresence(event) {
    const present = 0;
    updatePlayerPresence(event, contextMenuData.identifier, present);
  }

  function updatePlayerPresence(event, playerId, newState) {
    const foundPost = posts.find(post => post.id === playerId);
    if (onListItemClick) {
      onListItemClick(event, playerId, foundPost.lastname, foundPost.firstname);
    }
    if (event.defaultPrevented) return;
    const requestBody = {
      playerId: playerId,
      eventId: eventId,
      present: newState
    };

    api.put(`/EventPlayer/UpdatePresent?playerId=${requestBody.playerId}&eventId=${requestBody.eventId}&present=${requestBody.present}`)
      .then(() => {
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].present = requestBody.present;
        setPosts(updatedPosts);
      })
      .catch(error => {
        setError(error.response.data);
        openPopup("eventplayererror", playerId);
      })
  };

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    startSuccessBarLoading("Spieler wird entfernt");

    if (selectedSendUnregisterMailStatus === 0 && foundPost.statusId < 2 && foundPost.type === 1) {
      sendUnregisterConfirmationMail(foundPost.id);
    }
    deletePlayerFromEvent(foundPost.id);
    closeSuccessBar();
  };

  function sendUnregisterConfirmationMail(playerId) {    
    api.post(`/Textblock/SendAsEmail?name=${"unregisterconfirmation"}&language=DE&eventId=${eventId}&playerId=${playerId}`)
      .then((response) => {
        
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", playerId);
      })
  }

  function deletePlayerFromEvent(playerId) {
    api.delete(`/EventPlayer/DeleteParticipant?playerId=${playerId}&eventId=${eventId}`)
      .then(() => {
        startSuccessBarSuccess("Speiler entfernt")
        const updatedPosts = posts.filter(post => post.id !== playerId);
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", playerId);
      })
  }

  //Info Popup
  const [info, setInfo] = useState({
    lastname: '',
    firstname: '',
    statusId: 0,
    equipment: 0,
    present: 0,
    phone: '',
    email: '',
    memo: '',
    priceCents: null
  });

  const showPlayerInfo = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    openPopup('eventplayer', contextMenuData.identifier);
  };

  //Edit Popup
  const memoInputRef = useRef();
  const priceInputRef = useRef();

  const handleEditEventPlayerConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const requestBody = {
      playerId: info.id,
      eventId: eventId,
      memo: memoInputRef.current.value,
      priceCents: priceInputRef.current.value
    }

    let requestLink = `/EventPlayer/Edit?playerId=${requestBody.playerId}&eventId=${eventId}`;
    if (requestBody.memo !== '') requestLink += `&memo=${requestBody.memo}`;
    if (requestBody.priceCents !== '') requestLink += `&priceCents=${requestBody.priceCents * 100}`;

    api.put(requestLink)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === info.id);
        updatedPosts[postIndex].memo = response.data.memo;
        updatedPosts[postIndex].priceCents = response.data.priceCents;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
    
  };

  const updatePriceCents = (event) => {
    const newValue = event.target.value;
    setInfo((prevInfo) => ({
      ...prevInfo,
      priceCents: newValue === '' ? null : newValue * 100
    }));
  };

  //Context menu
  const { contextMenuData } = useContextMenu();

  function callPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    window.location.href = `tel:${foundPost.phone}`;
  }

  function emailPlayer() {
    navigate(`/mail/${eventId}/${contextMenuData.identifier}`);
  }

  function editPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(x => x = foundPost);
    openPopup('editeventplayer', contextMenuData.identifier);
  }

  function openPlayerWindowFromContextMenu() {
    openPlayerWindow(contextMenuData.identifier);
  }

  function openPlayerWindowFromPopup() {
    closePopup();
    openPlayerWindow(popupData.identifier);
  }

  function openPlayerWindow(playerId) {
    navigate(`/users/${playerId}`);
  }

  function toggleEquipment() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    const requestBody = {
      playerId: foundPost.id,
      eventId: eventId,
      equipment: foundPost.equipment ? 0 : 1
    }

    api.put(`/EventPlayer/UpdateNeedsEquipment?eventId=${requestBody.eventId}&playerId=${requestBody.playerId}&brauchtLeihausruestung=${requestBody.equipment}`)
      .then((response) => {
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].equipment = response.data.equipment;
        updatedPosts[postIndex].priceCents = response.data.priceCents;
        setPosts(updatedPosts);
      })
      .catch(error => {
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
  }

  function removeParticipant() {
    openPopup("confirmdeleteeventplayer", contextMenuData.identifier);
  }

  function removeReservation() {
    openPopup("confirmdeletereservation", contextMenuData.identifier);
  }

  function convertWaitlistToPlayer() {
    startSuccessBarLoading("Änderungen werden übernommen");
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    const requestBody = {
      playerId: foundPost.id,
      eventId: eventId
    }

    api.put(`/EventPlayer/ConvertReservationOrWaitlist?playerId=${requestBody.playerId}&eventId=${requestBody.eventId}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        let updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts.splice(postIndex, 1);
        updatedPosts = [...updatedPosts, response.data];
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
  }

  function removeWaitlist() {
    openPopup("confirmdeletewaitlist", contextMenuData.identifier);
  }

  return (
    <React.Fragment>
      <main className={posts.lenth > 0 ? "extendedMain hasPosts" : "extendedMain"}>
        <ListHeader gridTemplateColumns={'repeat(6, 1fr)'}>
          <ListTextItem isHeader>Mem</ListTextItem>
          <ListTextItem isHeader>Nach</ListTextItem>
          <ListTextItem isHeader>Vor</ListTextItem>
          <ListTextItem isHeader>Leih</ListTextItem>
          <ListTextItem isHeader>Zahlen</ListTextItem>
          <ListTextItem isHeader>Da</ListTextItem>
        </ListHeader>
        {posts.map((post, index) => (
          <React.Fragment key={post.id}>
            {post.type === 1 && 
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'repeat(6, 1fr)'}
                memo={post.memo}
                contextMenuIdentifier='eventplayer'
                index={index}
                onClick={handleListItemClick}
              >
                <StatusDot type='member' statusId={post.statusId} />
                <ListTextItem>{post.lastname}</ListTextItem>
                <ListTextItem>{post.firstname}</ListTextItem>
                <StatusDot type='equipment' statusId={post.equipment} />
                <ListTextItem>{post.priceCents === 0 ? "" : (post.priceCents / 100).toFixed(2) + '€'}</ListTextItem>
                <Checkbox className={"playerPresent"} identifier={post.id} selectedState={post.present} />
                <ListSymbols>
                  <ListSymbol icon={"note_stack"} visible={post.memo !== ''} type={0} />
                  <ListSymbol icon={"send"} visible={post.hasReceivedConfirmationEmail === 0} type={1} />
                </ListSymbols>
              </ListItem>
            }
            {post.type === 2 && 
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'repeat(3, 1fr)'}
                memo={""}
                contextMenuIdentifier='reservationplayer'
                index={index}
              >
                <ListTextItem type={1}><p>Reserviert</p></ListTextItem>
                <ListTextItem type={1}><p>{post.email}</p></ListTextItem>
                <StatusDot type='equipment' statusId={post.equipment} />
                <ListSymbols>
                  <ListSymbol icon={"note_stack"} visible={post.memo !== ''} type={0} />
                  <ListSymbol icon={"send"} visible={post.hasReceivedConfirmationEmail === 0} type={1} />
                </ListSymbols>
              </ListItem>
            }
            {post.type === 3 && 
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'repeat(4, 1fr)'}
                memo={""}
                contextMenuIdentifier='waitlistplayer'
                index={index}
              >
                <ListTextItem type={2}><p>Warteliste</p></ListTextItem>
                <ListTextItem type={2}><p>{post.email}</p></ListTextItem>
                <ListTextItem type={2}><p>{`${new Date(post.createDate).getDate()}.${new Date(post.createDate).getMonth() + 1}.${new Date(post.createDate).getFullYear()} ${new Date(post.createDate).getHours()}:${new Date(post.createDate).getMinutes()}`}</p></ListTextItem>
                <StatusDot type='equipment' statusId={post.equipment} />
                <ListSymbols>
                  <ListSymbol icon={"note_stack"} visible={post.memo !== ''} type={0} />
                  <ListSymbol icon={"send"} visible={post.hasReceivedConfirmationEmail === 0} type={1} />
                  <ListSymbol icon={"hourglass_empty"} visible={post.hasReceivedWaitlistFreeSpotEmail === 1} type={0} />
                </ListSymbols>
              </ListItem>
            }
          </React.Fragment>
        ))}
      </main>
      <ContextMenu type={'eventplayer'}>
        <ContextMenuItem label={'Anrufen'} icon={'call'} type={2} onClick={callPlayer} />
        <ContextMenuItem label={'E-Mail'} icon={'mail'} type={3} onClick={emailPlayer} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showPlayerInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayer} />
        <ContextMenuItem label={'Öffnen'} icon={'folder_open'} type={0} onClick={openPlayerWindowFromContextMenu} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Zurücksetzen'} icon={'history'} type={0} onClick={resetPlayerPresence} />
        <ContextMenuItem label={'Leihausrüstung'} icon={'star'} type={0} onClick={toggleEquipment} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={removeParticipant} />
      </ContextMenu>
      <ContextMenu type={'reservationplayer'}>
        <ContextMenuItem label={'E-Mail'} icon={'mail'} type={3} onClick={emailPlayer} />
        <ContextMenuItem label={'Vervollständigen'} icon={'edit'} type={0} onClick={openPlayerWindowFromContextMenu} />
        <ContextMenuItem label={'Hinzufügen'} icon={'add'} type={2} onClick={convertWaitlistToPlayer} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showPlayerInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayer} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Leihausrüstung'} icon={'star'} type={0} onClick={toggleEquipment} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={removeReservation} />
      </ContextMenu>
      <ContextMenu type={'waitlistplayer'}>
        <ContextMenuItem label={'E-Mail'} icon={'mail'} type={3} onClick={emailPlayer} />
        <ContextMenuItem label={'Vervollständigen'} icon={'edit'} type={0} onClick={openPlayerWindowFromContextMenu} />
        <ContextMenuItem label={'Hinzufügen'} icon={'add'} type={2} onClick={convertWaitlistToPlayer} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showPlayerInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayer} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Leihausrüstung'} icon={'star'} type={0} onClick={toggleEquipment} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={removeWaitlist} />
      </ContextMenu>
      <Popup name='confirmdeleteeventplayer' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Du bist dabei, den gewählten Spieler vom Spieltag zu entfernen (abzumelden).</p>
        <p>Soll der Spieler zusätzlich eine Bestätigungsmail über die erfolgreiche Abmeldung erhalten?</p>
        <CustomComboBox selected={selectedSendUnregisterMailStatus} onStatusChange={setSelectedSendUnregisterMailStatus}>
          <StatusDot type='yesno' statusId={0} />
          <StatusDot type='yesno' statusId={1} />
        </CustomComboBox>
      </Popup>
      <Popup name='confirmdeletereservation' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du die Reservierung von diesem Event entfernen willt?</p>
      </Popup>
      <Popup name='confirmdeletewaitlist' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du den Wartelisteneintrag von diesem Event entfernen willt?</p>
      </Popup>
      <Popup name='warningdeleteeventplayer' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleChangePlayerPresence} withCancel
      >
        <p>Der Admin Account ist nicht dafür vorgesehen, die Anwesenheit eines Spielers zu Ändern. Möchtest du dennoch fortfahren?</p>
      </Popup>
      <Popup name='warningchangeeventplayer' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleChangePlayerPresence} withCancel
      >
        <p>Der Spieltag liegt in der Vergangenheit oder in der Zukunft. Möchtest du trotzdem fortfahren?</p>
      </Popup>
      <Popup name='eventplayer' confirmText='Ok' headerText='Info' type="info">
        <h3>Memo:</h3>
        <div className={"memoOutput"}>
          {info.memo.split('\\n').map((zeile, index) => (
          index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
        ))}</div>
        <h3>Angepasster Preis:</h3>
        <p>{info.priceCents === null ? 'Standard' : `${info.priceCents / 100}€` }</p>
      </Popup>
      <Popup name='editeventplayer' confirmText={'Übernehmen'} onConfirm={handleEditEventPlayerConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <PopupDescriptionItem>Diese Memo wird nur für den aktuellen Spieltag angezeigt. Falls sie auch bei zukünftigen Spieltagen angezeigt werden soll, nutze bitte die 
          <PopupDescriptionLinkItem onClick={openPlayerWindowFromPopup}> allgemeine Spielermemo </PopupDescriptionLinkItem>.
        </PopupDescriptionItem>
        <textarea ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
        <h3>Preis:</h3>
        <input type='number' ref={priceInputRef} className={"priceInput"} min={0} max={10000} step={0.5} placeholder='Standard' value={info.priceCents === null ? "" : info.priceCents / 100} onChange={updatePriceCents} />
      </Popup>
      <Popup name='eventplayererror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default EventList