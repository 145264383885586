import React from 'react';
import './List.css';

function ListHeader({children, gridTemplateColumns = '1fr', size = 'big'}) {
  return (
    <div className={`listContainerHeader ${size}`} style={{gridTemplateColumns: `${gridTemplateColumns}`}}>
      {children}
    </div>
  );
}

export default ListHeader