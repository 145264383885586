import React, { useState, useEffect } from 'react';
import Navbar from '../nav/Navbar';
import NextEvent from '../home/NextEvent';
import '../styles/MainContainer.css'
import api from '../wrapper/API';
import SmallHomeOption from '../home/SmallHomeOption';

function Home() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api.get('/Event/GetFutureCombined')
      .then(response => {
        if (response.data.length !== 0) {
          setPosts(response.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data)
      });

    if (posts.length === 0) {
      api.get('/Event/GetAllCombined')
      .then(response => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error.response.data)
      });
    }
  }, [posts.length]);

  return (
    <React.Fragment>
      <Navbar homeSelected={true} extended={false} />
      <main className={"defaultMain"}>
        <div className={"homeContainer"}>
          {posts[0] ?
            <NextEvent key={posts[0].id} post={posts[0]} /> :
            <NextEvent post={posts[0]} />
          }
          <SmallHomeOption navigate={"/rules"} icon={"gavel"} text={"Regelwerk"} />
          <SmallHomeOption navigate={"/prices"} icon={"sell"} text={"Preisliste"} />
          <SmallHomeOption navigate={"/gamemodes"} icon={"flag"} text={"Spielmodi"} />
          <SmallHomeOption navigate={"/airsoftbomb"} icon={"bomb"} text={"Airsoft-Bombe Tutorial"} />
          <SmallHomeOption navigate={"/textblockeditor"} icon={"edit"} text={"Textbausteine bearbeiten"} />
        </div>
      </main>
    </React.Fragment>
  );
}

export default Home;