import React from 'react'
import Navbar from '../nav/Navbar'
import TextblockEditorScreen from '../textblockeditor/TextblockEditorScreen'

function Textblockeditor() {
  return (
    <React.Fragment>
      <Navbar extended={true} />
      <div className={"generalBigContainer"}>
        <TextblockEditorScreen />
      </div>
    </React.Fragment>
  )
}

export default Textblockeditor