import React, { useRef, useState } from 'react';
import './Login.css';
import api from '../wrapper/API';
import { motion } from 'framer-motion';
import logo from '../../images/favicon.ico';
import Textfield from '../input/Textfield';
import { useSuccessBar } from '../context/SuccessBarContext';
import { useNavigate } from 'react-router-dom';

function ForgotPasswordForm() {
  const emailTextFieldRef = useRef();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const {startSuccessBarSuccess} = useSuccessBar();
  const navigate = useNavigate();

  const handleSubmit = (event) => { 
    setLoading(true);
    event.preventDefault();   
    const requestBody = {
      email: email
    }
    
    api.post(`/Login/SendForgotPasswordLink?email=${requestBody.email}`)
      .then((response) => {
        setLoading(false);
        setError(null);
        setMessage("Es wurde eine Email an dich gesendet. Befolge die Anweisungen in der Email, um dein Passwort zurückzusetzen.");
        startSuccessBarSuccess("Wiederherstellungslink gesendet")
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data);
      })
  };

  return (
    <React.Fragment>
      <div className={"loginContainer"}>
        <div className={"loginFormContainer"}>
          <img className={"logo"} src={logo} />
          <p className={"loginLabel"}>Passwort zurücksetzen</p>
          <form onSubmit={handleSubmit}>
            <div className={"textFieldGeneralContainer"}>
              <Textfield icon={"alternate_email"} ref={emailTextFieldRef} type={"email"} required={true} name={"email"} placeholder={"E-Mail"} onChange={(e) => setEmail(e.target.value)} value={email}  />
            </div>
            <div className={"forgotPassword"} onClick={() => navigate("/login")}>Zurück zum Login</div>
            {error !== null && <p className={"loginError"}>{error}</p>}
            {message !== null && <p className={"loginMessage"}>{message}</p>}
            <div className={"submitContainer"}>
              {loading ?  <div className="submitLoadingContainer">
                {[1, 2, 3].map((i) => {
                  return (
                    <motion.div
                      key={i} 
                      className="submitLoadingIcon"
                      animate={{
                        scale: [1, 1.8, 1]
                      }}
                      transition={{
                        delay: i * 0.2,
                        duration: 0.6,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 0.5
                      }}
                    ></motion.div>
                  )
                })}
              </div> : <input className="submitButton" type='submit' value='Wiederherstellungslink senden' />}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForgotPasswordForm