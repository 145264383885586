import React, { useEffect } from 'react';
import { useSuccessBar } from '../context/SuccessBarContext';
import './SuccessBar.css';
import { motion, AnimatePresence } from 'framer-motion';

function SuccessBar() {
  const {successBarState, successBarMessage, closeSuccessBar} = useSuccessBar();

  useEffect(() => {
    if (successBarState === "success") {
      setTimeout(() => {
        closeSuccessBar();
      }, 2000)
    }
  }, [successBarState, closeSuccessBar]);

  const pathVariants = {
    initial: {
      pathLength: 0,
      pathOffset: 1,
    },
    visible: {
      pathLength: 1,
      pathOffset: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  }

  const barVariants = {
    initial: {
      y: -70
    },
    visible: {
      y: 4,
      transition: {
        duration: 0.15,
        ease: "easeOut"
      }
    },
    exit: {
      y: -70,
      transition: {
        duration: 0.15,
        ease: "easeIn"
      }
    }
  }

  return (
    <AnimatePresence>
      {successBarState === "success" &&  
        <motion.div 
          className={"successBarContainer success"}
          variants={barVariants}
          exit="exit"
        >
          <motion.svg
            fill="#000000"
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            id="check-mark-circle-2"
            data-name="Flat Line"
            xmlns="http://www.w3.org/2000/svg"
            className={"successBarIcon"}
          >
            <motion.polyline
              id="primary"
              points="21 5 12 14 8 10"
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
              }}
              className={"successBarIconPath"}
              variants={pathVariants}
              initial="initial"
              animate="visible"
            />
            <motion.path
              id="primary-2"
              data-name="primary"
              d="M20.94,11A8.26,8.26,0,0,1,21,12a9,9,0,1,1-9-9,8.83,8.83,0,0,1,4,1"
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
              }}
              className={"successBarIconPath"}
              variants={pathVariants}
              initial="initial"
              animate="visible"
            />
          </motion.svg>
          <p className={"successBarMessage"}>{successBarMessage}</p>
        </motion.div>}
      {successBarState === "loading" && 
        <motion.div 
          className={"successBarContainer loading"}
          variants={barVariants}
          initial="initial"
          animate="visible"
        >
          <motion.div
            className={"loadingIcon"}
            animate={{
              rotate: [0, 180, 360], 
              borderRadius: ["20%", "40%", "20%"],
              transition: {
                duration: 1,
                repeat: Infinity,
                ease: "linear",
                type: "tween"
              }
            }}
            exit={{
              scale: 0,
              transition: {
                duration: 0.15
              }
            }}
          >

          </motion.div>
          <p className={"successBarMessage"}>{successBarMessage}</p>
        </motion.div>
      }
    </AnimatePresence>
  )
}

export default SuccessBar