import React, { forwardRef, useState } from 'react';
import './CustomDropdown.css';
import Icon from '../home/Icon';

function CustomDropdown({children, icon = 'email', fill = false, value = "", onChange = () => {}}, ref) {
  const [dropdownValue, setDropdownValue] = useState(value);

  const handleOptionChange = (event) => {
    setDropdownValue(event.target.value);
    onChange(event);
  };

  return (
    <div className={`customDropdownContainer ${icon ? 'withIcon' : 'withoutIcon'}`}>
      {icon && (
        <div className={"customDropdownIconContainer"}>
          <Icon className={`customDropdownIcon ${fill ? "filled" : "notfilled"}`} icon={icon} />
        </div>
      )}
      <select ref={ref} value={dropdownValue} onChange={handleOptionChange}>
        {children}
      </select>
      <div className={"dropdownArrowContainer"}>
        <Icon className={`customDropdownArrowIcon ${fill ? "filled" : "notfilled"}`} icon={"expand_more"} />
      </div>
    </div>
  )
}

export default forwardRef(CustomDropdown)