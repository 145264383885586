import React from 'react';
import './EditPopup.css';

function PopupDescriptionItem({ children }) {
  return (
    <p className={"customPopupDescription"}>
      {children}
    </p>
  )
}

export default PopupDescriptionItem