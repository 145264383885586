import React, { createContext, useContext, useState } from 'react';
import '../styles/variables.css'

const DarkModeContext = createContext();
const rootElement = document.body;

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(prevIsDarkMode => !prevIsDarkMode);
    if (!isDarkMode) {
      rootElement.classList.add('darkTheme');
      localStorage.setItem('darkMode', 'true')
    } else {
      rootElement.classList.remove('darkTheme');
      localStorage.setItem('darkMode', 'false')
    }
  };

  let darkMode = localStorage.getItem('darkMode');
  if (darkMode === undefined) {
    localStorage.setItem('darkMode', 'false');
    darkMode = 'false';
  }

  if (darkMode === 'true' && !isDarkMode) {
    toggleDarkMode();
  }

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};