import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import SubmitButton from '../input/SubmitButton';
import './Form.css';
import { motion, useAnimation } from 'framer-motion';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';

function CreateForm({children, onSubmit, icon = 'person_add', error = null, tooltip = ''}) {
  const {openPopup} = usePopup();

  const handleSubmit = (event) => {
    if (onSubmit) {
      onSubmit(event);
    }
    //handleFormControlsClick();
  };

  useEffect(() => {
    if (window.innerWidth < 1000 && error !== null) {
      openPopup("createerror");
    }
  }, [error, openPopup])

  const [formVariants, setFormVariants] = useState({hidden: {top: window.innerWidth < 1000 ? "100dvh" : null}});
  const control = useAnimation();
  const [isFormVisible, setFormVisible] = useState(false);
  const formRef = useRef();

  const iconContainerVariants = {
    hidden: {
      x: "-50%",
      y: "-90px"
    },
    visible: {
      x: "-50%",
      y: "-80px"
    }
  }

  const iconVariants = {
    hidden: {
      rotate: 90,
      transition: {
        stiffness: 200
      }
    },
    visible: {
      rotate: 270,
      transition: {
        stiffness: 200
      }
    }
  };

  const overlayVariants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: "none"
      },
    },
    visible: {
      opacity: 0.5,
      display: "block"
    }
  };

  const handleFormControlsClick = () => {
    if (window.innerWidth < 1000) {
      control.start(isFormVisible ? "hidden" : "visible");
      setFormVisible((prev) => !prev);
    }
  };

  // Use useLayoutEffect to set the initial position of the form when it is first rendered
  useLayoutEffect(() => {
    if (formRef.current && window.innerWidth < 1000) {
      setFormVariants({
        hidden: {
          top: "100dvh"
        },
        visible: {
          top: `calc(100dvh - ${formRef.current.offsetHeight}px - 10px)`
        }
      });
    }
  }, [formRef]);

  return (
    <React.Fragment>
      <motion.div 
        className={"formOverlay"}
        variants={overlayVariants}
        initial="hidden"
        animate={control}
      >
      </motion.div>
      <motion.aside
        ref={formRef} 
        className={"formContainer"}
        variants={formVariants}
        initial="hidden"
        animate={control}
      >
        <motion.div 
          onClick={handleFormControlsClick} 
          className={"formControls"}
          variants={iconContainerVariants}
        >
          <motion.span 
            className={"material-symbols-rounded formControlsIcon"}
            variants={iconVariants}
          >
            arrow_back_ios_new
          </motion.span>
        </motion.div>
        <form onSubmit={handleSubmit}>
          {children}
          {onSubmit && <SubmitButton icon={icon} tooltip={tooltip} />}
          {error !== null && window.innerWidth >= 1000 && <p className={"newError"}>{error}</p>}
        </form>
      </motion.aside>
      <Popup name='createerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default CreateForm