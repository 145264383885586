import React, { useEffect, useRef, useState } from 'react'
import '../styles/MainContainer.css'
import { Link } from 'react-router-dom';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import StatusDot from '../status/StatusDot';
import './UpcomingEventsList.css';
import '../popup/EditPopup.css'
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import Analytics from '../analytics/Analytics';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import ListTextItem from '../list/ListTextItem';
import EventStatus from '../status/EventStatus';
import ListSymbols from '../input/ListSymbols';
import ListSymbol from '../input/ListSymbol';

function UpcomingEventsList({posts, setPosts}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [error, setError] = useState("");
  const {openPopup, closePopup, popupData} = usePopup();
  const [textblocks, setTextblocks] = useState([]);

  useEffect(() => {
    api
      .get(`/Textblock/GetAllNames?language=DE`)
      .then((response) => {
        setTextblocks(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Event wird abgesagt");
    const foundPostIndex = posts.findIndex(post => post.id === contextMenuData.identifier);
  
    api
      .put(`/Event/CancleEvent?eventId=${contextMenuData.identifier}`)
      .then(() => {
        startSuccessBarSuccess("Event abgesagt");
        const updatedPosts = [...posts];
        updatedPosts[foundPostIndex].statusId = 4;
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", posts[foundPostIndex].id);
      });
  
    closePopup();
  };

  const handleOpenPopup = () => {
    openPopup("confirmdeleteevent", contextMenuData.identifier);
  };

  //Info Popup
  const [info, setInfo] = useState({
    memo: '',
    date: '',
    statusId: 0
  });

  const showEventInfo = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    openPopup('event', foundPost.id);
  };

  //Edit Popup
  const memoInputRef = useRef();

  const handleEditEventConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const foundPost = posts.find(post => post.id === popupData.identifier);

    const requestBody = {
      eventId: foundPost.id,
      memo: memoInputRef.current.value
    }

    api.put(`/Event/AddMemo?eventId=${requestBody.eventId}&memo=${requestBody.memo}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].memo = response.data.memo;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", requestBody.eventId);
      })
  };

  //ContextMenu
  const { contextMenuData } = useContextMenu();

  function editEvent() {
    openPopup('editevent', contextMenuData.identifier);
  }

  function selectRoundmail() {
    openPopup('roundmailselection', contextMenuData.identifier);
  }

  const handleRoundmailListitemClick = (event, identifier) => {
    openPopup('confirmsendroundmail', identifier);
  };

  const handleConfirmSendRoundmail = () => {
    startSuccessBarLoading("Rundmail wird gesendet")
    const requestBody = {
      eventId: contextMenuData.identifier,
      textblock: popupData.identifier,
      language: "DE"
    }

    api.post(`/Textblock/SendRoundMail?eventId=${requestBody.eventId}&textblock=${requestBody.textblock}&language=${requestBody.language}`)
      .then((response) => {
        startSuccessBarSuccess("Rundmail gesendet");
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", requestBody.eventId);
      })
  }

  function showAnalytics() {
    startSuccessBarLoading("Statistiken werden geladen");
    api.get(`/Event/GetEventStats?eventId=${contextMenuData.identifier}`)
      .then(response => {
        const stats = response.data;
        setCards( 
          [
            {
              title: "Einnahmen", 
              icon: "price_check", 
              stats: [
                {
                  title: "Einnahmen",
                  value: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  max: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  negative: false,
                  suffix: "€"
                },
                {
                  title: "Lohn Orga",
                  value: (stats.lossSalaryOrga / 100).toFixed(2),
                  max: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  negative: true,
                  suffix: "€"
                },
                {
                  title: "David bekommt",
                  value: (stats.profitSendToDavid / 100).toFixed(2),
                  max: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  negative: false,
                  suffix: "€"
                },
                {
                  title: "Lohn Mail",
                  value: (stats.lossSalaryMail / 100).toFixed(2),
                  max: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  negative: true,
                  suffix: "€"
                },
                {
                  title: "Gewinn",
                  value: (stats.profitTotal / 100).toFixed(2),
                  max: (stats.profitEstimatedWithoutNotPresent / 100).toFixed(2),
                  negative: false,
                  suffix: "€"
                }
              ]
            },
            {
              title: "Anwesenheit", 
              icon: "check_small", 
              stats: [
                {
                  title: "Anwesend",
                  value: stats.presenceTotal,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                }
              ]
            },
            {
              title: "Anteil", 
              icon: "compare_arrows", 
              stats: [
                {
                  title: "Orga",
                  value: stats.countOrga,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                },
                {
                  title: "Member",
                  value: stats.countMember,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                },
                {
                  title: "Spieler",
                  value: stats.countPlayer,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                }
              ]
            }
          ]
        )
        closeSuccessBar();
        openPopup("eventanalytics", contextMenuData.identifier);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", contextMenuData.identifier);
      });
  }

  const cancelEvent = () => {
    handleOpenPopup();
  };

  const activateEvent = () => {
    startSuccessBarLoading("Event wird aktiviert");
    const foundPostIndex = posts.findIndex(post => post.id === contextMenuData.identifier);
  
    api
      .put(`/Event/ActivateEvent?eventId=${contextMenuData.identifier}`)
      .then((response) => {
        startSuccessBarSuccess("Event aktiviert");
        const updatedPosts = [...posts];
        updatedPosts[foundPostIndex].statusId = response.data.statusId;
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", posts[foundPostIndex].id);
      });
  }

  //Analytics
  const [cards, setCards] = useState([
    {
      title: "Einnahmen", 
      icon: "attach_money", 
      stats: [
        {}
      ]
    },
    {
      title: "Anwesenheit", 
      icon: "person", 
      stats: [
        {}
      ]
    }
  ]);

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        <ListHeader gridTemplateColumns={'1fr 1fr 1fr 1fr'}>
          <ListTextItem isHeader>Datum</ListTextItem>
          <ListTextItem isHeader>Teilnehmer</ListTextItem>
          <ListTextItem isHeader>Leih</ListTextItem>
          <ListTextItem isHeader>Status</ListTextItem>
        </ListHeader>
        {posts.map((post, index) => (
            <Link to={`/event/${post.id}/${post.date}`} key={post.id}>
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'1fr 1fr 1fr 1fr'}
                memo={post.memo}
                contextMenuIdentifier='upcomingevents'
                index={index}
                type={post.type === 0 ? 'normal' : 'grayedout'}
              >
                <ListTextItem>{`${new Date(post.date).getDate()}.${new Date(post.date).getMonth() + 1}.${new Date(post.date).getFullYear()}`}</ListTextItem>
                <ListTextItem>
                  <div className={"participantsContainer"}>
                    <p>{post.participants}</p> <p className={"dummyPlayers"}>{post.reservations > 0 && "+" + post.reservations}</p>
                  </div>
                </ListTextItem>
                <ListTextItem>{post.equipment}</ListTextItem>
                <EventStatus statusId={post.statusId} />
                <ListSymbols>
                  <ListSymbol icon={"note_stack"} visible={post.memo !== ''} type={0} />
                </ListSymbols>
              </ListItem>
            </Link>
          ))
        }
      </main>
      <ContextMenu type={'upcomingevents'}>
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showEventInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editEvent} />
        <ContextMenuItem label={'Rundmail'} icon={'mail'} type={3} onClick={selectRoundmail} />
        <ContextMenuItem label={'Statistiken'} icon={'show_chart'} type={0} onClick={showAnalytics} />
        {posts.find(post => post.id === contextMenuData.identifier)?.statusId === 4 ? (
          <ContextMenuItem label={'Aktivieren'} icon={'event_available'} type={2} onClick={activateEvent} />
        ) : (
          <ContextMenuItem label={'Absagen'} icon={'event_busy'} type={1} onClick={cancelEvent} />
        )}
      </ContextMenu>
      <Popup name='confirmdeleteevent' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du das Event absagen möchtest?</p>
      </Popup>
      <Popup name='event' headerText='Infos' type="info">
        <h3>Memo:</h3>
        <div className={"memoOutput"}>
          {info.memo.split('\\n').map((zeile, index) => (
          index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
        ))}</div>
        <h3>Datum:</h3>
        <p>{info.date}</p>
        <h3>Status:</h3>
        <StatusDot type='event' statusId={info.statusId} style={{alignSelf: 'center', justifySelf: 'center'}} />
      </Popup>
      <Popup name='editevent' confirmText={'Hinzufügen'} onConfirm={handleEditEventConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <textarea required ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
      </Popup>
      <Popup name='eventanalytics' confirmText={'Ok'} type="info">
        <Analytics cards={cards} />
      </Popup>
      <Popup name='eventerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
      <Popup name="roundmailselection" confirmText="Senden" type="info" withCancel withConfirm={false}>
        <div className='selectRoundmailContainer'>
          {textblocks.map((textblock, index) => (
            <ListItem 
              key={textblock.name}
              identifier={textblock.name}
              gridTemplateColumns={'1fr'}
              index={index}
              onClick={handleRoundmailListitemClick}
            >
              <ListTextItem>{textblock.frontendname}</ListTextItem>
            </ListItem>
          ))}
        </div>
      </Popup>
      <Popup name='confirmsendroundmail' confirmText='Ja' headerText='Achtung' type="warning" withCancel onConfirm={handleConfirmSendRoundmail}>
        <p>Du bist dabei, eine Email an alle Gastspieler zu senden, die für den Spieltag angemeldet sind. Möchtest du wirklich fortfahren?</p>
      </Popup>
    </React.Fragment>
  )
}

export default UpcomingEventsList