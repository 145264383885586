import React, { useCallback, useEffect, useState } from 'react';
import { useEditor, BubbleMenu, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import History from '@tiptap/extension-history';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import HardBreak from '@tiptap/extension-hard-break';
import './TextblockEditorScreen.css';
import '../styles/MainContainer.css'
import Icon from '../home/Icon';
import CustomDropdown from '../input/CustomDropdown';
import { useSuccessBar } from '../context/SuccessBarContext';
import { usePopup } from '../context/PopupContext';
import api from '../wrapper/API';
import TextblockEditorPlaceholder from './TextblockEditorPlaceholder';
import Tooltip from '../tooltip/Tooltip';
import SubmitButton from '../input/SubmitButton';
import Popup from '../popup/Popup';
import PopupDescriptionItem from '../popup/PopupDescriptionItem';

function TextblockEditorScreen() {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const {openPopup} = usePopup();
  const [selectedTextBlock, setSelectedTextBlock] = useState("");
  const [selectedTextBlockName, setSelectedTextBlockName] = useState("");
  const [selectedTextBlockSubject, setSelectedTextBlockSubject] = useState("");
  const [selectedTextBlockFrontendName, setSelectedTextBlockFrontendName] = useState("");
  const [error, setError] = useState("");
  const [textblocks, setTextblocks] = useState([]);
  const [popupName, setPopupName] = useState("");
  const [popupFrontendName, setPopupFrontendName] = useState("");
  const [popupSubject, setPopupSubject] = useState("");

  useEffect(() => {
    api
      .get(`/Textblock/GetAllNames?language=DE`)
      .then((response) => {
        setTextblocks(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      BulletList,
      ListItem,
      Bold,
      Italic,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https'
      }),
      Underline,
      History,
      Color,
      TextStyle,
      HardBreak
    ],
    content: ""
  });

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null
  }

  const handleDropdownChange = (event) => {
    if (!event.target.value) return;
    api
      .get(`/Textblock/GetByNameRaw?name=${event.target.value}&language=DE`)
      .then((response) => {
        setSelectedTextBlock(response.data.textHtml);
        setSelectedTextBlockName(response.data.name);
        setSelectedTextBlockFrontendName(response.data.frontendname);
        setSelectedTextBlockSubject(response.data.subject);
        editor.commands.setContent(response.data.textHtml);
      })
      .catch((error) => {
        setError(error.response.data);
        openPopup("textblockrerror", 1);
      });
  };

  const handleResetClick = () => {
    editor.commands.setContent("");
    setSelectedTextBlock("");
    setSelectedTextBlockName("");
    setSelectedTextBlockFrontendName("");
    setSelectedTextBlockSubject("");
  };

  const handleCreateClick = () => {
    openPopup("createtextblock");
  };

  const handleCreateTextblockConfirm = (event) => {
    startSuccessBarLoading("Textbaustein wird erstellt")
    api
    .post(`/Textblock/CreateTextblock?name=${popupName}&frontendname=${popupFrontendName}&subject=${popupSubject}&textHtml=${editor.getHTML()}&language=${"DE"}`)
    .then((response) => {
      startSuccessBarSuccess("Textbaustein wurde erstellt");
      handleResetClick();
    })
    .catch((error) => {
      closeSuccessBar();
      setError(error.response.data);
      openPopup("textblockrerror", 1);
    });
  };

  const handleDeleteClick = () => {
    openPopup("warningdeletetextblock");
  };

  const handleConfirmDeleteTextblock = () => {
    startSuccessBarLoading("Textbaustein wird gelöscht")
    api
    .delete(`/Textblock/DeleteTextblock?name=${selectedTextBlockName}&language=${"DE"}`)
    .then((response) => {
      startSuccessBarSuccess("Textbaustein wurde gelöscht");
      handleResetClick();
    })
    .catch((error) => {
      closeSuccessBar();
      setError(error.response.data);
      openPopup("textblockrerror", 1);
    });
  };

  const handleEditClick = () => {
    openPopup("edittextblock");
  };

  const handleConfirmEditTextblock = () => {
    startSuccessBarLoading("Textbaustein wird angepasst")
    api
    .put(`/Textblock/EditTextblock?name=${selectedTextBlockName}&frontendname=${selectedTextBlockFrontendName}&subject=${selectedTextBlockSubject}&textHtml=${editor.getHTML()}&language=${"DE"}`)
    .then((response) => {
      startSuccessBarSuccess("Textbaustein wurde angepasst");
      handleResetClick();
    })
    .catch((error) => {
      closeSuccessBar();
      setError(error.response.data);
      openPopup("textblockrerror", 1);
    });
  };

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        <div className={"innerContainerTextblockEditor"}>
          <p className={"innerContainerLabel"}>Textbaustein auswählen</p>
          <CustomDropdown value={""} onChange={handleDropdownChange} icon={"message"}>
            <option value="" disabled hidden></option>
            {textblocks.map((textblock, index) => (
              <option key={index} value={textblock.name}>{textblock.frontendname}</option>
            ))}
          </CustomDropdown>
          <p className={"innerContainerLabel"}>Bearbeiten</p>
          <BubbleMenu editor={editor}>
            <div className={"bubbleMenu"}>
              <Tooltip text="Rückgängig">
                <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().undo()}>
                  <Icon className={"bubbleIcon"} icon={"undo"} />
                </button>
              </Tooltip>
              <Tooltip text="Wiederholen">
                <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().redo()}>
                  <Icon className={"bubbleIcon"} icon={"redo"} />
                </button>
              </Tooltip>
              <Tooltip text="Fett">
                <button 
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  className={editor.isActive("bold") ? "isActive" : ""}
                >
                  <Icon className={"bubbleIcon"} icon={"format_bold"} />
                </button>
              </Tooltip>
              <Tooltip text="Kursiv">
                <button 
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  className={editor.isActive("italic") ? "isActive" : ""}
                >
                  <Icon className={"bubbleIcon"} icon={"format_italic"} />
                </button>
              </Tooltip>
              <Tooltip text="Link">
                <button 
                  onClick={setLink} 
                  className={editor.isActive('link') ? 'is-active' : ''}
                >
                  <Icon className={"bubbleIcon"} icon={"link"} />
                </button>
              </Tooltip>
              <Tooltip text="Liste">
                <button
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                  <Icon className={"bubbleIcon"} icon={"format_list_bulleted"} />
                </button>
              </Tooltip>
              <Tooltip text="Farbe">
                <input
                  type="color"
                  onInput={event => editor.chain().focus().setColor(event.target.value).run()}
                  value={editor.getAttributes('textStyle').color}
                  data-testid="setColor"
                />
              </Tooltip>
              <Tooltip text="Farbe zurücksetzen">
                <button
                  onClick={() => editor.chain().focus().unsetColor().run()}
                  data-testid="unsetColor"
                >
                  <Icon className={"bubbleIcon"} icon={"format_color_reset"} />
                </button>
              </Tooltip>
            </div>
          </BubbleMenu>
          <EditorContent editor={editor} />
          <div className={"textblockEditorButtonContainer"}>
            {
              !selectedTextBlock && 
              <SubmitButton icon={"add"} onClick={handleCreateClick} styletype={"smallround"} tooltip={"Neuen Textbaustein erstellen"} type={2} />
            }
            <SubmitButton icon={"replay"} onClick={handleResetClick} styletype={"smallround"} tooltip={"Alle Felder leeren"} />
            {
              selectedTextBlock &&
              <React.Fragment>
                <SubmitButton icon={"edit"} onClick={handleEditClick} styletype={"smallround"} tooltip={"Textbaustein bearbeiten"} />
                <SubmitButton icon={"delete_sweep"} onClick={handleDeleteClick} styletype={"smallround"} tooltip={"Textbaustein löschen"} type={1} />
              </React.Fragment>
            }
          </div>
          <p className={"innerContainerLabel"}>Verfügbare Platzhalter</p>
          <TextblockEditorPlaceholder />
        </div>
      </main>
      <Popup name='createtextblock' confirmText={'Übernehmen'} onConfirm={handleCreateTextblockConfirm} withCancel type="info">
        <PopupDescriptionItem>Bitte fülle die restlichen Felder aus.</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Name' onChange={(event) => {setPopupName(event.target.value)}} value={popupName} />
        <PopupDescriptionItem>Der Name wird später ausschließlich für technische zwecke gebraucht. Beispiel wäre "confirmation"</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Frontend Name' onChange={(event) => {setPopupFrontendName(event.target.value)}} value={popupFrontendName} />
        <PopupDescriptionItem>Der Frontend Name wird im Dropdown angezeigt. Beispiel wäre "Bestätigungsmail"</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Titel' onChange={(event) => {setPopupSubject(event.target.value)}} value={popupSubject} />
        <PopupDescriptionItem>Der Titel der Email. Beispiel wäre "Spieltag Bestätigung für [EventDate]"</PopupDescriptionItem>
        <TextblockEditorPlaceholder />
      </Popup>
      <Popup name='edittextblock' confirmText={'Übernehmen'} onConfirm={handleConfirmEditTextblock} withCancel type="info">
        <PopupDescriptionItem>Bitte fülle die restlichen Felder aus.</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Name' onChange={(event) => {setSelectedTextBlockName(event.target.value)}} value={selectedTextBlockName} />
        <PopupDescriptionItem>Der Name wird später ausschließlich für technische zwecke gebraucht. Beispiel wäre "confirmation"</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Frontend Name' onChange={(event) => {setSelectedTextBlockFrontendName(event.target.value)}} value={selectedTextBlockFrontendName} />
        <PopupDescriptionItem>Der Frontend Name wird im Dropdown angezeigt. Beispiel wäre "Bestätigungsmail"</PopupDescriptionItem>
        <input type='text' className={"textblockPopupInput"} placeholder='Titel' onChange={(event) => {setSelectedTextBlockSubject(event.target.value)}} value={selectedTextBlockSubject} />
        <PopupDescriptionItem>Der Titel der Email. Beispiel wäre "Spieltag Bestätigung für [EventDate]"</PopupDescriptionItem>
        <TextblockEditorPlaceholder />
      </Popup>
      <Popup name='textblockrerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
      <Popup name='warningdeletetextblock' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleConfirmDeleteTextblock} withCancel
      >
        <p>Möchtest du den Textbaustein "{selectedTextBlockName}" wirklich dauerhaft löschen?</p>
      </Popup>
    </React.Fragment>
  )
}

export default TextblockEditorScreen