import React, { useRef, useState } from 'react'
import '../styles/MainContainer.css'
import ContextMenu from '../ContextMenu/ContextMenu'
import ContextMenuItem from '../ContextMenu/ContextMenuItem'
import { useContextMenu } from '../context/ContextMenuContext'
import '../popup/EditPopup.css'
import CustomComboBox from '../input/CustomComboBox'
import Icon from '../home/Icon'
import './Wiki.css'
import api from '../wrapper/API'
import { useSuccessBar } from '../context/SuccessBarContext'
import Popup from '../popup/Popup'
import { usePopup } from '../context/PopupContext'
import WikiListItem from '../list/WikiListItem'

function WikiList({posts, setPosts, withOrderNumber = false, withPriority = false}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [error, setError] = useState("");
  const {openPopup, closePopup} = usePopup();

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Regel wird gelöscht");
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    api.delete(`/Wiki/Delete?id=${foundPost.id}&type=${foundPost.type}`)
      .then((response) => {
        startSuccessBarSuccess("Regel gelöscht");
        const updatedPosts = response.data;
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("ruleserror", foundPost.id);
      })
    closePopup();
  };

  const handleOpenPopup = () => {
    openPopup("confirmdeleterule", contextMenuData.identifier);
  };

  //Context menu
  const { contextMenuData } = useContextMenu();

  function editRule() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(x => x = foundPost);
    setSelectedPriority(foundPost.priority - 1);
    openPopup("editrule", contextMenuData.identifier);
  }

  function deleteRule() {
    handleOpenPopup();
  }

  //Edit Popup
  const [selectedPriority, setSelectedPriority] = useState(0);
  const [info, setInfo] = useState({

  });

  const titleInputRef = useRef();
  const descriptionInputRef = useRef();

  const handleEditRuleConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const requestBody = {
      id: info.id,
      title: titleInputRef.current.value,
      description: descriptionInputRef.current.value,
      priority: selectedPriority + 1,
      type: info.type
    }

    let requestLink = `/Wiki/Edit?id=${requestBody.id}&title=${requestBody.title}&description=${requestBody.description}&priority=${requestBody.priority}&type=${info.type}`;
    api.put(requestLink)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === info.id && post.type == info.type);
        updatedPosts[postIndex].title = response.data.title;
        updatedPosts[postIndex].description = response.data.description;
        updatedPosts[postIndex].priority = response.data.priority;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("ruleserror", requestBody.id);
      })
    
  };

  const updateTitle = (event) => {
    const newValue = event.target.value;
    setInfo((prevInfo) => ({
      ...prevInfo,
      title: newValue === '' ? '' : newValue
    }));
  };

  const updateDesc = (event) => {
    const newValue = event.target.value;
    setInfo((prevInfo) => ({
      ...prevInfo,
      description: newValue === '' ? '' : newValue
    }));
  };

  return (
    <React.Fragment>
      <main className={posts.lenth > 0 ? "extendedMain hasPosts" : "extendedMain"}>
        {posts.map((post, index) => (
          <WikiListItem 
            key={post.id} id={post.id} title={post.title} description={post.description} 
            priority={post.priority} orderNumber={post.orderNumber} index={index} withOrderNumber={withOrderNumber} withPriority={withPriority} 
          />
        ))}
      </main>
      <ContextMenu type={'rules'}>
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editRule} />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={deleteRule} />
      </ContextMenu>
      <Popup name='confirmdeleterule' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du die Regel dauerhaft löschen willst?</p>
      </Popup>
      <Popup name='editrule' confirmText={'Übernehmen'} onConfirm={handleEditRuleConfirm} withCancel type="info">
        <h3>Titel:</h3>
        <input required type='text' ref={titleInputRef} className={"textInput"} placeholder='Titel' value={info.title} onChange={updateTitle} />
        <h3>Beschreibung:</h3>
        <textarea required ref={descriptionInputRef} className={"memoInput"} maxLength={1000} placeholder='Memoeintrag hinzufügen' value={info.description} onChange={updateDesc}></textarea>
        {withOrderNumber &&
          <React.Fragment>
            <h3>Priorität</h3>
            <CustomComboBox selected={selectedPriority} onStatusChange={setSelectedPriority}>
              <div></div>
              <Icon className={"priorityIcon"} icon='priority_high' />
            </CustomComboBox>
          </React.Fragment>
        }
      </Popup>
      <Popup name='ruleserror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default WikiList