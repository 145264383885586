import React, { useEffect, useState } from 'react'
import './StatusDot.css'
import Tooltip from '../tooltip/Tooltip';

function StatusDot({type = 'member', statusId = 0, style = {}, className = ''}) {
  let output = '';
  const [tooltiptext, setTooltiptext] = useState('');

  useEffect(() => {
    if (type === 'member') {
      switch (statusId) {
        case 1:
          setTooltiptext('Spieler');
          break;
        case 2:
          setTooltiptext('Member');
          break;
        case 3:
          setTooltiptext('Orga');
          break;
      }
    } else if (type === 'event') {
      switch (statusId) {
        case 1:
          setTooltiptext('Erstellt');
          break;
        case 2:
          setTooltiptext('Geplant');
          break;
        case 3:
          setTooltiptext('Erfolgreich');
          break;
        case 4:
          setTooltiptext('Abgesagt');
      } 
    } else if (type === 'equipment') {
      switch (statusId) {
        case 0:
          setTooltiptext('Ohne Leihausrüstung');
          break;
        case 1:
          setTooltiptext('Mit Leihausrüstung');
          break;
      }
    } else if (type == 'yesno') {
      switch (statusId) {
        case 0:
          setTooltiptext('Ja');
          break;
        case 1:
          setTooltiptext('Nein');
          break;
      }
    }
  }, [tooltiptext])

  if (type === 'member') {
    output = <div className={'statusDotContainer'}><div id={statusId} className={`statusDot member ${className}`} style={style}></div></div>
  } else if (type === 'event') {
    output = <div className={'statusDotContainer'}><div id={statusId} className={`statusDot event ${className}`} style={style}></div></div>
  } else if (type === 'equipment') {
    output = <div className={'statusDotContainer'}><div id={statusId} className={`statusDot equipment ${className}`} style={style}></div></div>
  } else {
    output = <div className={'statusDotContainer'}><div id={statusId} className={`statusDot yesno ${className}`} style={style}></div></div>
  }

  return (
    <Tooltip text={tooltiptext}>
      {output}
    </Tooltip>
  )
}

export default StatusDot