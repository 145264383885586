import React from 'react';
import Navbar from '../nav/Navbar';
import '../player/EditPlayerScreen.css'
import EditPlayerScreen from '../player/EditPlayerScreen';
import { useParams } from 'react-router-dom';

function Players() {
  const { playerId } = useParams();

  return (
    <React.Fragment>
      <Navbar usersSelected={true} extended={true} />
      <div className={"generalPlayerContainer"}>
        <EditPlayerScreen playerId={playerId} />
      </div>
    </React.Fragment>
  );
}

export default Players;