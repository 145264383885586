import React, { useState } from 'react';
import './NextEvent.css';
import './Home.css';
import Icon from './Icon';
import StatusDot from '../status/StatusDot';
import ListSymbols from '../input/ListSymbols';
import ListSymbol from '../input/ListSymbol';
import { Link } from 'react-router-dom';
import EventStatus from '../status/EventStatus';

export default function NextEvent({post}) {

  return (
    <React.Fragment>
      {post ? 
        <Link 
          to={`/event/${post.id}/${post.date}`} 
          className={"nextEventContainer bigContainer"}
        >
          <React.Fragment>
            <div className={"nextEventDateContainer"}>
              <p className={"nextEventDate"}>{new Date(post.date).getDate()}</p>
              <p className={"nextEventMonth"}>{new Date(post.date).toLocaleString('default', { month: 'long' })}</p>
            </div>
            <div className={"nextEventInfoContainer"}>
              <div className={"nextEventInfoItem"}>
                <Icon className={"nextEventIcon"} icon='star' />
                <p className={"nextEventInfoText"}>Leihausrüstungen</p>
              </div>
              <p className={"nextEventInfoValue"}>{post.equipment}</p>
              <div className={"nextEventInfoItem"}>
                <Icon className={"nextEventIcon"} icon='person' />
                <p className={"nextEventInfoText"}>Teilnehmer</p>
              </div>
              <p className={"nextEventInfoValue"}>{post.participants}</p>
              <div className={"nextEventInfoItem"}>
                <Icon className={"nextEventIcon"} icon='leaderboard' />
                <p className={"nextEventInfoText"}>Status</p>
              </div>
              <EventStatus statusId={post.statusId} style={{marginLeft: '30px'}} />
            </div>
          </React.Fragment>
          <ListSymbols>
            <ListSymbol icon={"note_stack"} visible={post && post.memo} />
          </ListSymbols>
        </Link> : null
      }
    </React.Fragment>
  )
}
