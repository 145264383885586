import React from 'react'

function PopupDescriptionLinkItem({children, onClick = () => {}}) {
  return (
    <p className={"customPopupLink"} onClick={onClick}>
      {children}
    </p>
  )
}

export default PopupDescriptionLinkItem