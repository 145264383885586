import React from 'react'
import { Link } from 'react-router-dom'
import Icon from './Icon'

function SmallHomeOption({navigate = '/', icon = 'gavel', text = ""}) {
  return (
    <Link to={navigate} className={"smallContainer"}>
      <Icon className={"icon"} icon={icon} />
      <p className={"text"}>{text}</p>
    </Link>
  )
}

export default SmallHomeOption