import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react'
import Icon from '../home/Icon';
import './Checkbox.css';
import Tooltip from '../tooltip/Tooltip';

function Checkbox({ className, selectedState, onClick, identifier }, ref) {
  const iconRef = useRef();
  const [tooltiptext, setTooltiptext] = useState("Nicht definiert");
  const [icon, setIcon] = useState("radio_button_unchecked");
  const [checkboxColorStyle, setCheckboxColorStyle] = useState("");

  useImperativeHandle(ref, () => ({
    iconRef
  }));

  useEffect(() => {
    if (selectedState === 1) {
      setIcon("done");
      setCheckboxColorStyle("present");
      setTooltiptext("Anwesend");
    } else if (selectedState === 2) {
      setIcon("close");
      setCheckboxColorStyle("notPresent");
      setTooltiptext("Abwesend");
    } else {
      setIcon("radio_button_unchecked");
      setCheckboxColorStyle("");
      setTooltiptext("Nicht definiert");
    }
  }, [selectedState]);

  

  const handleClick = () => {
    if (onClick) {
      onClick(identifier);
    }
  };

  return (
    <Tooltip text={tooltiptext}>
      <div className={"checkboxContainer"} ref={ref} onClick={handleClick}>
        <Icon ref={iconRef} className={`${className} checkbox ${checkboxColorStyle}`} icon={icon} />
      </div>
    </Tooltip>
  )
}

export default forwardRef(Checkbox)