import React, { useRef, useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import api from '../wrapper/API';
import { motion } from 'framer-motion';
import { usePopup } from '../context/PopupContext';
import Popup from '../popup/Popup';
import logo from '../../images/favicon.ico';
import Textfield from '../input/Textfield';

function LoginForm() {
  const navigate = useNavigate();

  const emailTextFieldRef = useRef();
  const passwortTextFieldRef = useRef();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => { 
    setLoading(true);
    event.preventDefault();   
    const requestBody = {
      email: emailTextFieldRef.current.value,
      password: passwortTextFieldRef.current.value
    }
    
    api.post(`/Login/Login`, requestBody)
      .then((response) => {
        setLoading(false);
        setError(null);
        localStorage.setItem("playerId", response.data.id);
        localStorage.setItem("statusId", response.data.statusId);
        if (response.data.hasChangedPassword === 0) {
          setOldPassword(requestBody.password);
          openPopup('editinitialpassword', 0);
        } else {
          navigate('/');
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data);
      })
  };

  //Edit Password
  const [passwordError, setPasswordError] = useState(undefined);
  const [oldPassword, setOldPassword] = useState("");
  const { openPopup, closePopup } = usePopup();
  const newPasswordInputRef = useRef();
  const newRepeatPasswordInputRef = useRef();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleChangePasswordConfirm = () => {
    if (newPasswordInputRef.current.value === oldPassword) {
      setPasswordError("Bitte wähle ein neues Passwort.");
      return;
    }
    if (newPasswordInputRef.current.value !== newRepeatPasswordInputRef.current.value) {
      setPasswordError('Die 2 Passwortfelder stimmen nicht überein.');
      return;
    }

    const requestBody = {
      oldPassword: oldPassword,
      newPassword: newPasswordInputRef.current.value
    }

    api.put(`/Login/ChangePassword`, requestBody)
      .then(() => {
        setPasswordError(undefined);
        closePopup();
        navigate('/');
      })
      .catch(error => {
        setPasswordError(error.response.data);
      })
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgotpassword')
  };

  return (
    <React.Fragment>
      <div className={"loginContainer"}>
        <div className={"loginFormContainer"}>
          <img className={"logo"} src={logo} />
          <p className={"loginLabel"}>Login</p>
          <form onSubmit={handleSubmit}>
            <div className={"textFieldGeneralContainer"}>
              <Textfield icon={"alternate_email"} ref={emailTextFieldRef} type={"email"} required={true} name={"email"} placeholder={"E-Mail"} onChange={(e) => setEmail(e.target.value)} value={email}  />
              <Textfield icon={"vpn_key"} ref={passwortTextFieldRef} type={"password"} required={true} name={"password"} placeholder={"Passwort"} onChange={(e) => setPassword(e.target.value)} value={password}  />
            </div>
            {error !== null && <p className={"loginError"}>{error}</p>}
            <div className={"forgotPassword"} onClick={handleForgotPasswordClick}>Passwort vergessen?</div>
            <div className={"submitContainer"}>
              {loading ?  <div className="submitLoadingContainer">
                {[1, 2, 3].map((i) => {
                  return (
                    <motion.div
                      key={i} 
                      className="submitLoadingIcon"
                      animate={{
                        scale: [1, 1.8, 1]
                      }}
                      transition={{
                        delay: i * 0.2,
                        duration: 0.6,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 0.5
                      }}
                    ></motion.div>
                  )
                })}
              </div> : <input className="submitButton" type='submit' value='Login' />}
            </div>
            <div className={"signUpLink"}>
              Noch kein Account?<a href='mailto:info@av-enztal.de?subject=Account%20Anfrage&body=Hallo,%0Aich%20melde%20mich%20wegen%20einer%20Account%20Anfrage%20in%20eurem%20Orga%20System.%20Hier%20sind%20die%20benötigten%20Daten:%0A-%20Vorname:%20[Hier%20den%20Vornamen%20eintragen]%0A-%20Nachname:%20[Hier%20den%20Nachnamen%20eintragen]%0A-%Telefonnummer:%20[Hier%20die%20Telefonnummer%20eintragen]%0A'>
                Kontaktiere uns
              </a>
            </div>
          </form>
        </div>
      </div>
      <Popup name='editinitialpassword' confirmText={'Übernehmen'} onConfirm={handleChangePasswordConfirm} dontCloseOnConfirm type="info">
        <h3>Bitte ändere dein Passwort;</h3>
        <input required type='password' ref={newPasswordInputRef} className={"textInput"} placeholder='Neues Passwort' />
        <input required type='password' ref={newRepeatPasswordInputRef} className={"textInput"} placeholder='Neues Passwort wiederholen' />
        {passwordError &&
          <p className={"newError"}>{passwordError}</p>
        }
      </Popup>
    </React.Fragment>
  )
}

export default LoginForm