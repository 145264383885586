import React from 'react';
import { useSuccessBar } from '../context/SuccessBarContext';
import Tooltip from '../tooltip/Tooltip';

function TextblockEditorPlaceholder() {
  const {startSuccessBarSuccess} = useSuccessBar();

  const handleOnClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(event.target.innerText);
    startSuccessBarSuccess("In Zwischenablage kopiert");
  }

  return (
    <div className={"placeholderTipps"}>
      <Tooltip text="Vorname des Spielers">
        <button onClick={handleOnClick}>[PlayerFirstname]</button>
      </Tooltip>
      <Tooltip text="Spieltag Datum">
        <button onClick={handleOnClick}>[EventDate]</button>
      </Tooltip>
      <Tooltip text="Preis Eintritt">
        <button onClick={handleOnClick}>[PriceTicket]</button>
      </Tooltip>
      <Tooltip text="Preis Leihausrüstung">
        <button onClick={handleOnClick}>[PriceEquipment]</button>
      </Tooltip>
      <Tooltip text="Kontaktdaten der Orgas eines Spialtages">
        <button onClick={handleOnClick}>[OrgaContactData]</button>
      </Tooltip>
      <Tooltip text="Tage bis zum Spieltag">
        <button onClick={handleOnClick}>[DaysTillEvent]</button>
      </Tooltip>
    </div>
  )
}

export default TextblockEditorPlaceholder