import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../nav/Navbar';
import '../wiki/Wiki.css'
import WikiList from '../wiki/WikiList';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import CreateForm from '../form/CreateForm';
import CustomComboBox from '../input/CustomComboBox';

function Gamemodes() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api
      .get(`/Wiki/Get?type=2`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const titleRef = useRef();
  const descRef = useRef();
  const orderNumberRef = useRef();

  const handleAddNewGamemodeClick = (event) => {
    startSuccessBarLoading("Spielmodus wird erstellt");
    event.preventDefault();
    const requestBody = {
      title: titleRef.current.value,
      description: descRef.current.value,
      orderNumber: orderNumberRef.current.value === '' ? 0 : orderNumberRef.current.value,
      priority: 1,
      type: 2
    }
    
    api.post('/Wiki/Create', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Spielmodus erstellt");
        const updatedPosts = response.data;
        setPosts(updatedPosts);

        setFormError(null);
        titleRef.current.value = '';
        descRef.current.value = '';
        orderNumberRef.current.value = '';
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  return (
    <React.Fragment>
      <Navbar extended={true}/>
      <div className={"generalContainer"} >
        <WikiList posts={posts} setPosts={setPosts} withOrderNumber />
        <CreateForm onSubmit={handleAddNewGamemodeClick} icon="gavel" error={formError} tooltip={"Spieltag erstellen"}>
          <input required ref={titleRef} type="text" placeholder="Titel" name="title" />
          <input required ref={descRef} type="text" placeholder="Beschreibung" name="description" />
          <input required ref={orderNumberRef} type="number" placeholder="Soritierung / Platz" name="order" />
        </CreateForm>
      </div>
    </React.Fragment>
  );
}

export default Gamemodes;